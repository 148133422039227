import React, { Component } from 'react'
import { Menu } from 'semantic-ui-react'
import { getUsername, logout } from "../utils/auth";
import myhistory from "./myhistory";
import cloud from "../assets/white-cloud.svg";

export default class TopMenu extends Component {
  state = {
    activeItem: myhistory.location.pathname.substring(1)
  };

  handleItemClick = (e, { name }) => myhistory.push("/" + name);

  render() {
    const { activeItem } = this.state;

    return (
      <Menu
        fixed="top"
        inverted={true}
        className="top-menu"
        size="small"
      >
        <div className="ui container">
          <Menu.Item className="header">
            <div>
              <img src={cloud} width={20} alt="Cloud"/>
              <span>Farecloud Hub</span>
            </div>
          </Menu.Item>

          <Menu.Item
            name='bookings'
            active={activeItem === 'bookings'}
            onClick={this.handleItemClick}
          >
            Bookings
          </Menu.Item>

          <Menu.Item
            name='transactions'
            active={activeItem === 'transactions'}
            onClick={this.handleItemClick}
          >
            Transactions
          </Menu.Item>

          <Menu.Item
            name='reports'
            active={activeItem === 'reports'}
            onClick={this.handleItemClick}
          >
            Reports
          </Menu.Item>

          <Menu.Item
            name='support'
            active={activeItem === 'support'}
            onClick={this.handleItemClick}
          >
            Support
          </Menu.Item>

          <Menu.Menu position='right'>
            <Menu.Item onClick={logout}>
              Log out ({getUsername()})
            </Menu.Item>
          </Menu.Menu>
        </div>
      </Menu>
    )
  }
}