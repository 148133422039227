import '../css/bookings.scss';

import React from 'react';
import Page from "./page";
import ReactTable from "react-table";
import 'react-table/react-table.css';
import axios from 'axios';
import { Link } from "react-router-dom";
import config from '../components/config'

class BookingsPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true
    };
    this.fetchData = this.fetchData.bind(this);
  }

  requestData(pageSize, page, sorted, filtered) {
    return new Promise((resolve, reject) => {

      // You can use the filters in your request, but you are responsible for applying them.
      // if (filtered.length) {
      //   filteredData = filtered.reduce((filteredSoFar, nextFilter) => {
      //     return filteredSoFar.filter(row => {
      //       return ( row[nextFilter.id] + "" ).includes(nextFilter.value);
      //     });
      //   }, filteredData);
      // }

      let orderBy = "id";
      let orderDesc = true;
      if (sorted.length > 0) {
        orderBy = sorted[0].id;
        orderDesc = sorted[0].desc;
      }

      let filters = {};
      if (filtered.length > 0)
        filtered.forEach(function (filter) {
          filters[filter.id] = filter.value;
        });

      return axios.get(config.apiHost + `bookings`, {
        params: {
          "orderBy": orderBy,
          "orderDesc": orderDesc,
          "pageSize": pageSize,
          "filter": JSON.stringify(filters),
          "page": page
        }
      })
        .then(response => resolve(response.data))
        .catch(error => reject(error));
    });
  }

  fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });

    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    this.requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
    });
  }

  renderAuth() {
    const columns = [
      {
        Header: 'Number',
        accessor: 'number',
        maxWidth: 90,
        Cell: (row) => (
          <span>
            <Link to={"/booking/" + row.value}>{row.value}</Link>
          </span>
        )
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        maxWidth: 130
      },
      {
        Header: 'Departure date',
        accessor: 'departureDate',
        maxWidth: 120
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Phone',
        accessor: 'phone'
      },
      {
        Header: 'Passengers',
        accessor: 'passengers',
        maxWidth: 120
      },
      {
        Header: 'Partner id',
        accessor: 'partnerId',
        maxWidth: 120
      },
      {
        Header: 'Status',
        accessor: 'status',
        maxWidth: 100,
        Cell: row => (
          <span>
            <span style={{
              color: row.value === 'failed' ? '#ff2e00'
                : row.value === 'new' ? '#E0E0E0'
                  : row.value === 'waiting' ? '#ffbf00'
                    : '#57d500',
              transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span> {row.value}
          </span>
        )
      },
      {
        Header: 'Is test',
        accessor: 'test',
        maxWidth: 80
      },
    ];

    const { data, pages, loading } = this.state;
    return (
      <div>
        <ReactTable
          columns={columns}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          data={data}
          pages={pages} // Display the total number of pages
          loading={loading} // Display the loading overlay when we need it
          onFetchData={this.fetchData} // Request new data when things change
          filterable
          defaultPageSize={100}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

export default BookingsPage;
