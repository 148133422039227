import '../css/reports.scss';

import React from 'react';
import Page from "./page";
import 'react-table/react-table.css';
import axios from 'axios';
import config from '../components/config'
import _ from "lodash";
import { Container, Dropdown, Header, Icon, Segment, Table } from "semantic-ui-react";

class ReportsPage extends Page {

  monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      data: null,
      groupOptions: [
        { key: 'day', text: 'Day', value: 'day' },
        { key: 'month', text: 'Month', value: 'month' }
      ],
      monthOptions: _.range(1, 13).map(month => {
        return { key: 'm' + month, text: this.monthNames[month - 1], value: month }
      }),
      yearOptions: _.range(2017, ( new Date() ).getFullYear() + 1).map(year => {
        return { key: 'y' + year, text: year, value: year }
      }),
      yearValue: ( new Date() ).getFullYear(),
      monthValue: ( new Date() ).getMonth(),
      groupValue: 'day'
    };
  }

  loadData() {
    this.setState({ loading: true });

    axios.get(config.apiHost + `reports/general`, {
      params: {
        groupBy: this.state.groupValue,
        month: this.state.monthValue,
        year: this.state.yearValue
      }
    })
      .then(response => {
        if (response.data.error !== "")
          return this.setState({ error: response.data.error, loading: false, data: null });

        return this.setState({ error: null, loading: false, data: response.data.data });
      })
      .catch(error => this.setState({ error: error.message }));
  }

  changeGroupBy(e, { value }) {
    if (this.state.groupValue !== value)
      this.setState(
        { groupValue: value },
        this.loadData
      );
  }

  changeMonth(e, { value }) {
    if (this.state.monthValue !== value)
      this.setState(
        { monthValue: value },
        this.loadData
      );
  }

  changeYear(e, { value }) {
    if (this.state.yearValue !== value)
      this.setState(
        { yearValue: value },
        this.loadData
      );
  }

  renderAuth() {
    if (this.state.error != null)
      return <div>
        <Container textAlign='center'>
          <Segment>
            <Icon color='red' name='exclamation triangle' size='huge'/><br/><br/>
            <strong>{this.state.error}</strong>
          </Segment>
        </Container>
      </div>;

    if (this.state.loading)
      return <div>
        <Container textAlign='center'>
          <Segment loading><br/><br/></Segment>
        </Container>
      </div>;

    return <div>
      <Container>
        <Header as='h1'>Reports</Header>
        <Segment>
          <span>
          Group by {' '}
            <Dropdown
              inline
              openOnFocus={false}
              options={this.state.groupOptions}
              value={this.state.groupValue}
              onChange={this.changeGroupBy.bind(this)}
            />
          </span>
          {
            this.state.groupValue === 'day' && <div className="monthSelector">
              <Dropdown
                inline
                openOnFocus={false}
                options={this.state.monthOptions}
                value={this.state.monthValue}
                onChange={this.changeMonth.bind(this)}
              />
              <Dropdown
                inline
                openOnFocus={false}
                options={this.state.yearOptions}
                value={this.state.yearValue}
                onChange={this.changeYear.bind(this)}
              />
            </div>
          }
        </Segment>
        <div>
          <Table celled attached unstackable compact size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Period</Table.HeaderCell>
                <Table.HeaderCell>Bookings count</Table.HeaderCell>
                <Table.HeaderCell>Total spendings, EUR</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                this.state.data && this.state.data.rows && this.state.data.rows.map(item => {
                  return <Table.Row verticalAlign='top' key={item.period}>
                    <Table.Cell verticalAlign='top'>{item.period}</Table.Cell>
                    <Table.Cell verticalAlign='top'>{item.count}</Table.Cell>
                    <Table.Cell verticalAlign='top'>{item.price}</Table.Cell>
                  </Table.Row>;
                })
              }
            </Table.Body>
          </Table>
        </div>
      </Container>
      <br/>
    </div>;
  }

  componentDidMount() {
    this.loadData();
  }

}

export default ReportsPage;
