import axios from "axios";
import myhistory from "../components/myhistory";

const USERNAME_KEY = 'access_user';
const ACCESS_TOKEN_KEY = 'access_token';

export function getUsername() {
  return localStorage.getItem(USERNAME_KEY);
}

export function logout() {
  clearAccessToken();
  myhistory.push("/");
}

export function checkAuth() {
  if (isLoggedIn())
    axios.defaults.headers.common['Authorization'] = 'Basic ' + getAccessToken();
}

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(USERNAME_KEY);
  axios.defaults.headers.common['Authorization'] = null;
}

// Get and store access_token in local storage
export function setAccessToken(username, accessToken) {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  localStorage.setItem(USERNAME_KEY, username);
  axios.defaults.headers.common['Authorization'] = 'Basic ' + accessToken;
}

export function isLoggedIn() {
  const idToken = getAccessToken();
  return !!idToken && idToken !== "null";
}