import '../css/booking.scss';

import React from 'react';
import Page from "./page";
import 'react-table/react-table.css';
import axios from 'axios';
import {Breadcrumb, Container, Grid, Header, Icon, Message, Segment, Tab, Table} from "semantic-ui-react";
import config from '../components/config';
import myhistory from '../components/myhistory';
import {formatLabel} from "../utils/utils";
import _ from "lodash";

class BookingPage extends Page {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      data: null,
      logsOpen: false,
      modalOpen: false
    };

    this.loadData()
  }

  loadData() {
    axios.get(config.apiHost + `booking/` + this.getNumber())
    .then(response => {
      if (response.data.error !== "")
        return this.setState({error: response.data.error, loading: false, data: null});

      return this.setState({error: null, loading: false, data: response.data.data});
    })
    .catch(error => this.setState({error: error.message}));
  }

  getNumber() {
    return this.props.match.params.number;
  }

  renderBreadcrumbs() {
    return <Container className="breadcrumbs">
      <Breadcrumb>
        <Breadcrumb.Section>Home</Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle'/>
        <Breadcrumb.Section onClick={x => myhistory.push("/bookings")}>Bookings</Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle'/>
        <Breadcrumb.Section active>{this.getNumber()}</Breadcrumb.Section>
      </Breadcrumb>
    </Container>
  }

  renderAuth() {
    if (this.state.error != null)
      return <div>
        {this.renderBreadcrumbs()}
        <Container textAlign='center'>
          <Segment>
            <Icon color='red' name='exclamation triangle' size='huge'/><br/><br/>
            <strong>{this.state.error}</strong>
          </Segment>
        </Container>
      </div>;

    if (this.state.loading) {
      return <div>
        {this.renderBreadcrumbs()}
        <Container textAlign='center'>
          <Segment loading><br/><br/></Segment>
        </Container>
      </div>
    }

    return <div>
      {this.renderBreadcrumbs()}
      {
        this.state.data.test &&
        <Message warning>
          <Message.Header>This is a test booking!</Message.Header>
        </Message>
      }
      <Container>
        <Header as='h1'>{this.state.data.number}</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              {this.renderMainInfo()}
            </Grid.Column>
            <Grid.Column width={12}>
              {this.renderPassengers()}<br/>
              {this.renderFlights()}<br/>
              {this.renderTransactions()}<br/>
              {this.renderPnrs()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <br/>
    </div>
  }

  showLoader() {
    this.setState({modalOpen: true});
  }

  hideLoader() {
    this.setState({modalOpen: false});
  }

  renderFlights() {
    return <div>
      <Message
        size="tiny"
        attached
        className="bg-light-green"
        header='Flights'
      />
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Flight</Table.HeaderCell>
            <Table.HeaderCell>Departure</Table.HeaderCell>
            <Table.HeaderCell>Arrival</Table.HeaderCell>
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.flights.map(flight => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell verticalAlign='top'>
                  {flight.fromFull.cityName} ({flight.fromFull.iata})
                  - {flight.toFull.cityName} ({flight.toFull.iata})<br/>
                  {flight.marketingCarrier} {flight.number}<br/>
                  Marketing carrier: {flight.marketingCarrierName}
                  {flight.operatingCarrier && flight.marketingCarrier !== flight.operatingCarrier &&
                  <span><br/>Operating carrier: {flight.operatingCarrier}</span>}
                  {flight.planeName && <span><br/>Plane: {flight.planeName}</span>}
                </Table.Cell>
                <Table.Cell>
                  {flight.departureDateTime}<br/>
                  Airport: {flight.fromFull.airportName}
                  {flight.departureTerminal && <span><br/>Terminal: {flight.departureTerminal}</span>}
                </Table.Cell>
                <Table.Cell>
                  {flight.arrivalDateTime}<br/>
                  Airport: {flight.toFull.airportName}
                  {flight.arrivalTerminal && <span><br/>Terminal: {flight.arrivalTerminal}</span>}
                </Table.Cell>
                <Table.Cell>
                  Free luggage: {flight.includedLuggage}<br/>
                  Segment index: {flight.segmentIndex}
                </Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  renderPassengers() {
    return <div>
      <Message
        size="tiny"
        attached
        className="bg-light-blue"
        header='Passengers'
      />
      <Table celled attached compact unstackable size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type, gender</Table.HeaderCell>
            <Table.HeaderCell>First name</Table.HeaderCell>
            <Table.HeaderCell>Last name</Table.HeaderCell>
            <Table.HeaderCell>Date of birth</Table.HeaderCell>
            <Table.HeaderCell>Nationality</Table.HeaderCell>
            <Table.HeaderCell>Passport</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.passengers.map(pax => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell>{pax.type === "A" ? "Adult" : (pax.type === "C" ? "Child" : "Infant")}, {pax.sex === "M" ? "Male" : "Female"}</Table.Cell>
                <Table.Cell>{pax.firstName}</Table.Cell>
                <Table.Cell>{pax.lastName}</Table.Cell>
                <Table.Cell>{pax.dateOfBirth}</Table.Cell>
                <Table.Cell>{pax.nationality}</Table.Cell>
                <Table.Cell>
                  {pax.passportNumber}
                  {
                    pax.passportExpirationDate &&
                    <div>valid till {pax.passportExpirationDate}</div>
                  }
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  renderTransactions() {
    return <div>
      <Message
        size="tiny"
        attached
        header='Partner transactions'
        className='bg-light-red'
      />
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Partner</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Comment</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.transactions.map(t => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell>{t.partner}</Table.Cell>
                <Table.Cell>{t.date}</Table.Cell>
                <Table.Cell>{t.amount} {t.currency}</Table.Cell>
                <Table.Cell>{t.type}</Table.Cell>
                <Table.Cell>{t.comment}</Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  renderPnrs() {
    let panes = this.state.data.pnrs.map((pnr, index) => {
      return {menuItem: 'Pnr #' + (index + 1), render: () => this.renderPnr(pnr, index)}
    });
    return <div>
      <br/>
      <Tab menu={{secondary: true, pointing: true}} panes={panes}/>
    </div>
  }

  renderPnr(pnr, index) {

    return <Tab.Pane>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={5}>
            <Table size="small" fluid celled unstackable attached width="100%">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>Info</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell className="name">Airline PNRs</Table.Cell>
                  <Table.Cell>{pnr.airlinePnrs.join("\n")}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Tickets</Table.Cell>
                  <Table.Cell>{pnr.ticketNumbers.join("\n")}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className="name">Status</Table.Cell>
                  <Table.Cell>
                    {formatLabel(pnr.status)}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan='2'>Extras</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.keys(pnr.extras).map((name) => {
                  return <Table.Row>
                    <Table.Cell className="name">{name}</Table.Cell>
                    <Table.Cell>
                      <input type="text" value={pnr.extras[name]} className="valueText" readOnly={true}/>
                    </Table.Cell>
                  </Table.Row>
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={11} textAlign="left">
            <div>
            <Message
              size="tiny"
              attached
              className="bg-light-green"
              header='Flights'
            />
            <Table celled attached unstackable compact size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Flight</Table.HeaderCell>
                  <Table.HeaderCell>Departure</Table.HeaderCell>
                  <Table.HeaderCell>Arrival</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  pnr.flights.map(flight => {
                    return <Table.Row verticalAlign='top'>
                      <Table.Cell verticalAlign='top'>
                        {flight.marketingCarrier} {flight.number}
                      </Table.Cell>
                      <Table.Cell>
                        {flight.from}<br/>
                        {flight.departureDateTime}
                      </Table.Cell>
                      <Table.Cell>
                        {flight.to}<br/>
                        {flight.arrivalDateTime}
                      </Table.Cell>
                    </Table.Row>
                  })
                }
              </Table.Body>
            </Table>
          </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>;

    return <div>
      <Message
        size="tiny"
        attached
        header='PNRs'
        className='bg-light-red'
      />
      <Table celled attached unstackable compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Partner</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Comment</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.data.transactions.map(t => {
              return <Table.Row verticalAlign='top'>
                <Table.Cell>{t.partner}</Table.Cell>
                <Table.Cell>{t.date}</Table.Cell>
                <Table.Cell>{t.amount} {t.currency}</Table.Cell>
                <Table.Cell>{t.type}</Table.Cell>
                <Table.Cell>{t.comment}</Table.Cell>
              </Table.Row>
            })
          }
        </Table.Body>
      </Table>
    </div>
  }

  renderMainInfo() {
    return <div>
      <Message
        size="tiny"
        attached
        header='Main info'
      />
      <Table compact attached unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="name">Number</Table.Cell>
            <Table.Cell>{this.state.data.number}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Created at</Table.Cell>
            <Table.Cell>{this.state.data.createdAt}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Email</Table.Cell>
            <Table.Cell>
              <small>{this.state.data.email}</small>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Phone</Table.Cell>
            <Table.Cell>{this.state.data.phone}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Partner id</Table.Cell>
            <Table.Cell>{this.state.data.partnerId}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Locators</Table.Cell>
            <Table.Cell className="locators">{this.state.data.locators.join(", ")}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Status</Table.Cell>
            <Table.Cell>{formatLabel(this.state.data.status)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Purchased luggage</Table.Cell>
            <Table.Cell>{this.state.data.luggageAdded || 'No'}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Price</Table.Cell>
            <Table.Cell>{this.state.data.price} EUR</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  }

}

export default BookingPage;
