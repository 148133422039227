import React, { Component } from 'react';
import { isLoggedIn } from "../utils/auth";
import Redirect from "react-router/es/Redirect";
import TopMenu from "../components/menu";

class Page extends Component {

  renderAuth() {
  }

  render() {
    if (!isLoggedIn())
      return <Redirect to="/"/>

    return <div>
      <TopMenu/>
      <div className="ui main container">
        {this.renderAuth()}
      </div>
    </div>
  }

}

export default Page;
