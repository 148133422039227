class Config {

  apiHost = Config.isDev()
    ? 'http://localhost:8081/hub/'
    : 'https://api.farecloud.io/hub/';

  webHost = Config.isDev()
    ? 'http://localhost:4000/'
    : 'https://hub.farecloud.io/';

  static isDev(): boolean {
    return (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    );
  }
}

let config = new Config();
window.config = config;

export default config;