import '../css/ticket.scss';

import React from 'react';
import Page from "./page";
import 'react-table/react-table.css';
import axios from 'axios';
import {
  Breadcrumb,
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Segment,
  Table,
  TextArea
} from "semantic-ui-react";
import config from '../components/config';
import myhistory from '../components/myhistory';
import {InputFile} from 'semantic-ui-react-input-file'
import {Link} from "react-router-dom";

class TicketPage extends Page {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      submitting: false,
      error: null,
      data: null,
      files: [],

      type: "other",
      booking: "",
      subject: "",
      text: "",
    };

    if (!this.isNew())
      this.loadData()
  }

  loadData() {
    axios.get(config.apiHost + `support/` + this.getNumber())
    .then(response => {
      if (response.data.error !== "")
        return this.setState({error: response.data.error, loading: false, data: null});

      return this.setState({error: null, loading: false, data: response.data.data});
    })
    .catch(error => this.setState({error: error.message}));
  }

  isNew() {
    return this.props.match.params.number === "new";
  }

  getNumber() {
    return this.isNew() ? "New Support Ticket" : this.props.match.params.number;
  }

  renderBreadcrumbs() {
    return <Container className="breadcrumbs">
      <Breadcrumb>
        <Breadcrumb.Section>Home</Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle'/>
        <Breadcrumb.Section onClick={x => myhistory.push("/support")}>Support</Breadcrumb.Section>
        <Breadcrumb.Divider icon='right angle'/>
        <Breadcrumb.Section active>{this.getNumber()}</Breadcrumb.Section>
      </Breadcrumb>
    </Container>
  }

  handleChange(e, {name, value}) {
    this.setState({[name]: value});
  }

  renderAuth() {
    if (this.state.error != null)
      return <div>
        {this.renderBreadcrumbs()}
        <Container textAlign='center'>
          <Segment>
            <Icon color='red' name='exclamation triangle' size='huge'/><br/><br/>
            <strong>{this.state.error}</strong>
          </Segment>
        </Container>
      </div>;

    if (this.isNew()) {
      let types = [
        {key: 'refund', text: 'Refund', value: 'refund'},
        {key: 'change', text: 'Change', value: 'change'},
        {key: 'pax-details', text: 'Passenger details', value: 'pax-details'},
        {key: 'extras', text: 'Service request', value: 'extras'},
        {key: 'other', text: 'Other', value: 'other'}
      ];

      return <div>
        {this.renderBreadcrumbs()}
        <Container>
          <Header as='h1'>Create new support request</Header>
          <Container textAlign='left'>
            <Segment>
              <Form>
                <Form.Group widths='equal'>
                  <Form.Select
                    label="Type of request"
                    name="type"
                    placeholder="Not set"
                    options={types}
                    onChange={this.handleChange.bind(this)}
                  />
                  <Form.Input
                    label="Booking number"
                    name="booking"
                    onChange={this.handleChange.bind(this)}
                  />
                  <Form.Input
                    label="Subject"
                    name="subject"
                    onChange={this.handleChange.bind(this)}
                  />
                </Form.Group>
              </Form>
            </Segment>
          </Container>
          {this.renderTextField()}
        </Container>
        <br/>
      </div>
    }

    if (this.state.loading) {
      return <div>
        {this.renderBreadcrumbs()}
        <Container textAlign='center'>
          <Segment loading><br/><br/></Segment>
        </Container>
      </div>
    }

    return <div>
      {this.renderBreadcrumbs()}
      <Container>
        <Header as='h1'>{this.state.data.conversation.id} - {this.state.data.conversation.subject}</Header>
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              {this.renderMainInfo()}
            </Grid.Column>
            <Grid.Column width={12}>
              {this.renderTextField()}<Divider/>
              {this.renderMessages()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <br/>
    </div>
  }

  renderTextField() {
    return <Segment color="yellow" loading={this.state.submitting}>
      <h3>Comment</h3>
      <Form>
        <TextArea placeholder='Tell us more' value={this.state.text} onChange={(e) => {
          this.setState({text: e.target.value});
        }}/>
        <div className="files">
          {
            this.state.files.map((f, index) => {
              return <div className="file" key={index}>
                {f.name}
                <span className="remove-file" onClick={() => {
                  let files = this.state.files;
                  files.splice(index, 1);
                  this.setState({files: files});
                }}>X</span>
              </div>;
            })
          }
        </div>
        <div className="buttons">
          <InputFile
            button={{}}
            input={{
              id: 'input-control-id',
              onChange: (e) => {
                this.setState({files: [...this.state.files, ...e.target.files]});
              }
            }}
          />
          <Button primary onClick={() => {
            if (this.state.text === "") {
              alert("Please type text");
              return;
            }

            var bodyFormData = new FormData();
            bodyFormData.set('text', this.state.text);
            this.state.files.forEach((f) => {
              bodyFormData.append("files[]", f, f.name);
            });

            if (this.isNew()) {
              bodyFormData.set('type', this.state.type);
              bodyFormData.set('booking', this.state.booking);
              bodyFormData.set('subject', this.state.subject);
            }

            this.setState({submitting: true});
            let self = this;

            axios({
              method: 'post',
              url: config.apiHost + `support/` + (this.isNew() ? "new" : this.getNumber()) + "/reply",
              data: bodyFormData,
              config: {headers: {'Content-Type': 'multipart/form-data'}}
            })
            .then(function (response) {
              if (self.isNew()) {
                myhistory.push("/support/" + response["data"]["conversation"]);
                return;
              }

              self.setState({
                submitting: false,
                text: "",
                files: [],
                loading: true
              });
              self.loadData();
            })
            .catch(function (response) {
              self.setState({submitting: false, loading: true});
              alert("Error sending reply - " + response);
              self.loadData();
            });
          }}>Send</Button>
        </div>
      </Form>
    </Segment>;
  }

  renderMessages() {
    return <div>
      <div className="messages">
        {
          this.state.data.messages.map(m => {
              let className = m.type;
              if (className === "message")
                className = "support";
              return <div key={m.id} className={`message ${className}`}>
                <div className="message-head">
                  <strong className="message-from">{m.from}</strong> <i className="message-date">{m.createdAt}</i>
                </div>
                <div className="message-text">
                  {m.type === "lineitem" ? <span>Status set to <b>{m.status}</b></span> : m.body}
                </div>
                <div className="message-attachments">
                  {m.files.map(a => {
                    return <div className="attch">
                      <a href={a.url} target="_blank">{a.name}</a>
                    </div>;
                  })}
                </div>
              </div>
            }
          )
        }
      </div>
    </div>;
  }

  renderMainInfo() {
    return <div>
      <Message
        size="tiny"
        attached
        header='Main info'
      />
      <Table compact attached unstackable>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="name">Number</Table.Cell>
            <Table.Cell>{this.state.data.conversation.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Created at</Table.Cell>
            <Table.Cell>{this.state.data.conversation.date}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Subject</Table.Cell>
            <Table.Cell>
              <small>{this.state.data.conversation.subject}</small>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="name">Status</Table.Cell>
            <Table.Cell>
      <span style={{
        color:
          this.state.data.conversation.status === 'active' ? '#E0E0E0'
            : this.state.data.conversation.status === 'pending' ? '#ffbf00'
            : '#57d500',
        transition: 'all .3s ease'
      }}>
      &#x25cf;
      </span> {this.state.data.conversation.status}
            </Table.Cell>
          </Table.Row>
          {
            this.state.data.conversation.booking !== "" &&
            <Table.Row>
              <Table.Cell className="name">Booking</Table.Cell>
              <Table.Cell>
                <Link
                  to={"/booking/" + this.state.data.conversation.booking}>{this.state.data.conversation.booking}</Link>
              </Table.Cell>
            </Table.Row>
          }
          {
            this.state.data.conversation.topic !== "" &&
            <Table.Row>
              <Table.Cell className="name">Topic</Table.Cell>
              <Table.Cell>
                {this.state.data.conversation.topic}
              </Table.Cell>
            </Table.Row>
          }
          <Table.Row>
            <Table.Cell className="name">Created by</Table.Cell>
            <Table.Cell>{this.state.data.conversation.createdBy}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  }

}

export default TicketPage;
