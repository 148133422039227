import '../css/login.scss';

import React from 'react';
import Page from "./page";
import myhistory from '../components/myhistory';
import { Container, Form, Grid, Message, Segment } from "semantic-ui-react";
import cloud from '../assets/cloud.svg';
import axios from 'axios';
import config from "../components/config";
import qs from "qs";
import { isLoggedIn, setAccessToken } from "../utils/auth";

class LoginPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      login: "",
      password: "",
      error: null
    };
  }

  loginMe() {
    this.setState({ loading: true, error: null });

    axios.post(config.apiHost + `login`, qs.stringify({
      login: this.state.login,
      password: this.state.password
    }))
      .then(response => {
        if (response.data.success) {
          let token = btoa(this.state.login + ':' + response.data.token);
          setAccessToken(this.state.login, token);
          myhistory.push("/bookings");
        } else {
          this.setState({
            error: response.data.error,
            loading: false
          })
        }
      })
      .catch(error => {
        this.setState({
          error: "HTTP error:" + error,
          loading: false
        })
      });
  }

  render() {
    return <div className="login-page">
      <Segment vertical className="spacer">
        <Container textAlign='center'>
          <img src={cloud} width={70} alt="Farecloud"/>
          <h3 style={{ marginTop: "5px" }}>Farecloud Hub</h3>
          <Grid columns={3}>
            <Grid.Row stretched>
              <Grid.Column/>
              <Grid.Column>
                <Segment color='blue' loading={this.state.loading}>
                  {this.state.error !== null &&
                  <Message
                    error
                    header='Error'
                    content={this.state.error}
                  />}
                  <Form onSubmit={this.loginMe.bind(this)}>
                    <Form.Field>
                      <label>Login</label>
                      <input required placeholder='Login' value={this.state.login} onChange={(e) => {
                        this.setState({ login: e.target.value });
                      }}/>
                    </Form.Field>
                    <Form.Field>
                      <label>Password</label>
                      <input required type='password' placeholder='Password' value={this.state.password} onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}/>
                    </Form.Field>
                    <Form.Button primary type="submit">Login</Form.Button>
                  </Form>
                </Segment>
              </Grid.Column>
              <Grid.Column/>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </div>
  }

  componentDidMount() {
    if (isLoggedIn())
      myhistory.push("/bookings");
  }

}

export default LoginPage;
