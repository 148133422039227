import React from 'react';
import {Route} from 'react-router';
import {Switch} from 'react-router-dom';
import LoginPage from "./pages/login";
import BookingsPage from "./pages/bookings";
import BookingPage from "./pages/booking";
import SupportPage from "./pages/support";
import TicketPage from "./pages/ticket";
import TransactionsPage from "./pages/transactions";
import ReportsPage from "./pages/reports";

const Routes = props => (
  <main>
    <Switch {...props}>
      <Route path="/booking/:number" exact component={BookingPage} />
      <Route path="/bookings" exact component={BookingsPage} />
      <Route path="/transactions" exact component={TransactionsPage} />
      <Route path="/reports" exact component={ReportsPage} />
      <Route path="/support" exact component={SupportPage} />
      <Route path="/support/:number" exact component={TicketPage} />
      <Route path="*" exact component={LoginPage} />
    </Switch>
  </main>
);

export default Routes;
