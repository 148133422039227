import { Router } from 'react-router-dom';
import Routes from './routes';
import React, { Component } from 'react';
import myhistory from './components/myhistory';
import { checkAuth } from "./utils/auth";

class App extends Component {

  constructor(props) {
    super(props);
    checkAuth();
  }

  pageChange(url) {
    // ym('hit', url);
    // ReactGA.pageview(url);
    // this.siftPageChange();
  }

  render() {
    return (
      <Router history={myhistory}>
        <div className="fullContainer">
          <div className="bodyContainer">
            <Routes/>
          </div>
          {/*<Footer/>*/}
        </div>
      </Router>
    )
  }
}

App.propTypes = {};

App.defaultProps = {
  options: {},
};

export default App;